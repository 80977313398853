<nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand">
    <img src="/assets/BNA-logo-no-slogan-002.png" width="160" height="40" class="d-inline-block align-top" alt=""/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div  class="collapse navbar-collapse font-weight-bold" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li  *ngIf="(_isLoggedIn$ | async)" class="nav-item active">
        <a class="nav-link text-success" routerLink="/">HOME <span class="sr-only"></span></a>
      </li>
      <li *ngIf="(_isLoggedIn$ | async)&&(_reportAccess$ | async)" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-success" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          REPORTS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item text-success" routerLink="/belbin-report/team-maps">TEAM MAPS</a>
          <a class="dropdown-item text-success" routerLink="/belbin-report/word-analysis">WORD ANALYSIS</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-success" routerLink="/belbin-report/image-removal">IMAGE REMOVAL</a>
        </div>
      </li>
      <li *ngIf="(_isLoggedIn$ | async)&&((_fullServiceFullAccess$ | async)||(_fullServiceLimitedAccess$ | async)||(_fullServiceLimitedAccessNoBilling$ | async))" class="nav-item">
        <a class="nav-link text-success" routerLink="/belbin-order">FULL SERVICE</a>
      </li>
    </ul>
    <div *ngIf="_isLoggedIn$ | async" class="text-success mr-5">
      <i class="fa fas fa-user"></i>
      {{ _userName$ | async }}
    </div>
    <form class="form-inline my-2 my-lg-0">
      <div *ngIf="_isLoggedIn$ | async">
        <i class="fa fa-fw fa-sign-out text-success"></i>
        <a class="text-success" (click)="onLogout()" href="#"><span></span>SIGN OUT</a>    
      </div>
      <div *ngIf="!(_isLoggedIn$ | async)">          
        <i class="fa fa-fw fa-sign-in text-success"></i>
        <a class="text-success" (click)="onLogin()" href="#"><span ></span>SIGN IN</a>              
      </div>
    </form>
  </div>
</nav>




import { Action, createReducer, on} from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { state } from '@angular/animations';
import jwt_decode from 'jwt-decode';
import { AuthAccess } from './models/auth-access.model';

export const authFeatureKey = 'auth';

export interface State {
  authLoading: boolean,
  authAccess: AuthAccess
}

export const initialState: State = {
  authLoading: true,
  authAccess: {
    isAutenticated: false,
    token: null,
    permissions: [],
  }
  
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, {token}) => {
    return ({...state, authLoading: false, authAccess: {isAutenticated: token!=null, token: token, permissions: jwt_decode(token)['permissions']}});
  }),
  on(AuthActions.login, state=>{
    return {...state, authLoading:false };
  }),
  on(AuthActions.logout, state=>({...state, authLoading:true })),
  on(AuthActions.logoutConfirmed, state => {
    return {...state, authLoading:false, authAccess: {token:null, isAutenticated: false, permissions: []}};
  }),
  on(AuthActions.loginFailure, state=>({...state, authLoading:false }))
);


export const selectIsLoggedIn = (state: State) => state.authAccess.isAutenticated;
export const selectToken = (state: State) => state.authAccess.token;
export const selectAuthLoading = (state: State) => state.authLoading;
export const selectAuthUserName = (state: State) => {
  if(state.authAccess&&state.authAccess.token){
    return jwt_decode(state.authAccess.token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
  }
  else{
    return null;
  }
}
export const selectAuthAccess = (state: State) => state.authAccess;

export const selectRolePermission = (state: State,role: string) => {
  let rolePermission = state.authAccess.permissions.find(item=>item==role);
  return (rolePermission && rolePermission.length>0);
};




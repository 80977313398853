import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodeItem } from './models/code-item.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../../app/state';
import { selectToken } from '../auth/auth.selectors';
import { CodeTableType} from './models/code-table-type.model';
import { CodeTable } from './models/code-table.model';

@Injectable()
export class CodeService {

    constructor(private _http :HttpClient, private _store: Store<fromStore.State>){
    }

    getCodeItems(codeTableType: CodeTableType, parentCodeTableType: CodeTableType): Observable<CodeTable>{


        let params = new HttpParams()
            .set("codeTableType",codeTableType==null?"":codeTableType)
            .set("parentCodeTableType",parentCodeTableType==null?"":parentCodeTableType)

        return this._store.select(selectToken).pipe(
            concatMap(token => {
                let headers:HttpHeaders = new HttpHeaders().set( 'Authorization', `Bearer ${token}`);    
                return this._http.get<CodeTable>(environment.auth.apiUrl+'Code', {headers: headers, params: params});
            })
        );
    }

    getCodeItemsPublic(codeTableType: CodeTableType, parentCodeTableType: CodeTableType): Observable<CodeTable>{


        let params = new HttpParams()
            .set("codeTableType",codeTableType==null?"":codeTableType)
            .set("parentCodeTableType",parentCodeTableType==null?"":parentCodeTableType)

        return this._store.select(selectToken).pipe(
            concatMap(token => {
                let headers:HttpHeaders = new HttpHeaders().set( 'Authorization', `Bearer ${token}`);    
                return this._http.get<CodeTable>(environment.auth.apiUrl+'CodePublic', {headers: headers, params: params});
            })
        );
    }
}
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../app/state';
import { login, logout } from '../auth/auth.actions';
import { Observable, of } from 'rxjs';
import { selectRolePermission, selectIsLoggedIn, selectAuthUserName } from '../auth/auth.selectors';
import { AuthRoles } from '../auth/models/auth-roles.enum';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public _isLoggedIn$ : Observable<boolean>;
  public _belbinApiAccess$ : Observable<boolean>;
  public _reportAccess$ : Observable<boolean>;
  public _fullServiceFullAccess$ : Observable<boolean>;
  public _fullServiceLimitedAccess$ : Observable<boolean>;
  public _fullServiceLimitedAccessNoBilling$ : Observable<boolean>;
  public _userName$ : Observable<string>;

  constructor(private _store: Store<fromStore.State>) { 
    
  }

  ngOnInit(): void {
    this._isLoggedIn$=this._store.select(selectIsLoggedIn);
    this._belbinApiAccess$ =this._store.select(selectRolePermission,AuthRoles.BelbinApiAccess);
    this._reportAccess$ =this._store.select(selectRolePermission,AuthRoles.ReportAccess);
    this._fullServiceFullAccess$ =this._store.select(selectRolePermission,AuthRoles.FullServiceFullAccess);
    this._fullServiceLimitedAccess$ =this._store.select(selectRolePermission,AuthRoles.FullServiceLimitedAccess);
    this._fullServiceLimitedAccessNoBilling$ =this._store.select(selectRolePermission,AuthRoles.FullServiceLimitedAccessNoBilling);
    this._userName$=this._store.select(selectAuthUserName);
  }

  onLogin(){
    this._store.dispatch(login({redirectUrl: ''}));
  }

  onLogout() {
    this._store.dispatch(logout());
  }



}

import { createAction, props } from '@ngrx/store';
import { CodeTableType } from './models/code-table-type.model';
import { CodeTable } from './models/code-table.model';



export const codeRequested = createAction(
    '[Everywhere] codeRequested',
    props<{criteria: {codeTableType : CodeTableType, parentCodeTableType: CodeTableType}}>()
);

export const codePublicRequested = createAction(
    '[Everywhere] codePublicRequested',
    props<{criteria: {codeTableType : CodeTableType, parentCodeTableType: CodeTableType}}>()
);

export const codeLoaded = createAction(
    '[Everywhere] codeLoaded',
    props<{codes : CodeTable}>()
);
import { Component, OnInit } from '@angular/core';
import { checkLogin } from './auth/auth.actions';
import * as fromStore from './state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'belbin';

  constructor(private _store: Store<fromStore.State>) { }

  ngOnInit() {
//    console.log('app-root','checklogin')
//    this._store.dispatch(checkLogin());
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './auth/components/callback/callback.component';
import { AuthGuardService } from './auth/auth-guard.service';


const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  { 
    path: 'belbin-order',
    loadChildren: () => import('./belbin-orders/belbin-orders.module').then(m => m.BelbinOrdersModule),
  },
  { 
    path: 'belbin-report',
    loadChildren: () => import('./belbin-reports/belbin-reports.module').then(m => m.BelbinReportsModule),
    canActivate: [AuthGuardService],
    data: { role: "exec:reports" }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

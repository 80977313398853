import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

//export const selectIsLoggedIn = (state: fromAuth.State) => state.isLoggedIn;
export const selectIsLoggedIn = createSelector(selectAuthState,fromAuth.selectIsLoggedIn);
export const selectToken = createSelector(selectAuthState,fromAuth.selectToken);
export const selectAuthLoading = createSelector(selectAuthState,fromAuth.selectAuthLoading);
export const selectAuthUserName = createSelector(selectAuthState, fromAuth.selectAuthUserName);
export const selectAuthAccess = createSelector(selectAuthState, fromAuth.selectAuthAccess);
export const selectRolePermission = createSelector(selectAuthState, fromAuth.selectRolePermission);


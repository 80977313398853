import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CodeItem } from './models/code-item.model';
import { CodeTable } from './models/code-table.model';
import { createReducer, on } from '@ngrx/store';
import * as CodeActions from './code.actions';

export const codesFeatureKey = 'codes';

export interface State extends EntityState<CodeTable> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<CodeTable> = createEntityAdapter<CodeTable>({selectId: (code: CodeTable) => code.name});

export const initialState: State = adapter.getInitialState({
  isLoading: false
});


export const reducer = createReducer(
  initialState,
  on(CodeActions.codeRequested, (state, {criteria})=> ({...state, isLoading: true})),
  on(CodeActions.codeLoaded, (state, { codes }) => {
    return adapter.setOne(codes, { ...state, isLoading: false })
  }),
  on(CodeActions.codePublicRequested, (state, {criteria})=> ({...state, isLoading: true})),
  on(CodeActions.codeLoaded, (state, { codes }) => {
    return adapter.setOne(codes, { ...state, isLoading: false })
  }),
);

// get the selectors
const {
  selectIds,
} = adapter.getSelectors();

export const selectCodeTable = (state: State, id: string) => {
    return state.entities[id];
}

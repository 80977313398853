import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthGuardService } from './auth-guard.service';

@NgModule({
    declarations: [
    CallbackComponent],
    imports: [
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects])],
    providers: [
      AuthGuardService
    ]
  })
  export class AuthModule { }
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../app/state';
import { take, concatMap, map, switchMap, skipUntil, skipWhile } from 'rxjs/operators';
import { selectAuthAccess, selectAuthLoading, selectIsLoggedIn } from './auth.selectors';
import { Observable, of } from 'rxjs';
import { checkLogin, login } from './auth.actions';
import { _runtimeChecksFactory } from '@ngrx/store/src/runtime_checks';
import { AuthAccess } from './models/auth-access.model';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private _store: Store<fromStore.State>){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> {
    return this.checkAuthentication().pipe(
      switchMap((item : AuthAccess)=>{
        if(item.isAutenticated){
          return of(item);
        }
        else{
          this._store.dispatch(checkLogin());
          return this._store.select(selectAuthLoading).pipe(
            skipWhile(item=>item),
            switchMap(item=>this.checkAuthentication()),
            switchMap( item=>{
              if(item.isAutenticated){
                return of(item.isAutenticated);
              }
              else{
                let redirectUrl: string = state.url.toLowerCase().startsWith("/belbin-order/edit")?location.href:"";
                this._store.dispatch(login({redirectUrl: redirectUrl}));
                return of(false);
              }
            }),
          )
        }
      }),
      map((item : AuthAccess)=>{
        return item.isAutenticated;
      })
    );
  }

  private checkAuthentication() : Observable<AuthAccess> {
    return this._store.select(selectAuthAccess);
  }

}


export const environment = {
  production: false,
  auth: {
    clientID: 'r6qW1rUOxdYA8DSJnTzUU19mBEPaho16',
    domain: '3cp.auth0.com', 
    redirect: 'https://belbin.3circlepartners.com/callback',
//    scope: 'download:belbin, upload:belbin',
    scope: 'belbinapi:access fullService:fullAccess fullService:limitedAccess fullService:limitedAccessNoBilling',
    logoutUrl: 'https://belbin.3circlepartners.com',
    apiUrl:"https://belbin.3circlepartners.com:50000/api/",
    audience: 'http://belbinapi.3circlepartners.com:50000/api/',
  }
};


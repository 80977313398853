import { createAction, props } from '@ngrx/store';
import { IdToken } from '@auth0/auth0-spa-js';

export const login = createAction(
  '[Login Page] login',
  props<{redirectUrl: string}>()
);
export const loginComplete  = createAction(
  '[Login Page] loginComplete'
);
export const loginSuccess  = createAction(
  '[Auth API] loginSuccess',
  props<{user: any, token: any}>()
);
export const loginFailure  = createAction(
  '[Auth API] loginFailure',
  props<{errorMessage: any}>()
);
export const checkLogin  = createAction(
  '[Auth] checkLogin'
);
export const logout = createAction(
  '[Auth] logout'
);
export const logoutCancelled = createAction(
  '[Auth] logoutCancelled'
);
export const logoutConfirmed  = createAction(
  '[Auth] logoutConfirmed '
);




import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../app/state';
import { checkLogin, loginComplete } from '../../auth.actions';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private _store: Store<fromStore.State>) { }

  ngOnInit(): void {
    this._store.dispatch(loginComplete());
//    console.log('callback','checklogin')
//    this._store.dispatch(checkLogin())
  }

}

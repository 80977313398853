import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';

import * as AuthActions from './auth.actions';
import { AuthService } from './auth.service';



@Injectable()
export class AuthEffects {

  constructor(private _actions$: Actions, private _authService : AuthService) {}

  login$ = createEffect(() => {
    return this._actions$.pipe( 
      ofType(AuthActions.login),
      concatMap((action)=>this._authService.login(action.redirectUrl)),
      map(() => AuthActions.loginComplete()),
      catchError(error => of(AuthActions.loginFailure({ errorMessage: error })))
    )
  });

  loginComplete$ = createEffect(()=>{
    return this._actions$.pipe(
      ofType(AuthActions.loginComplete),
      concatMap(()=>this._authService.callback()),
      map((result) => {
        console.log("effectLoginComplete", result);
        return AuthActions.loginSuccess(result);
      }),
    )
  });

  checkLogin$ = createEffect(()=>{
    return this._actions$.pipe(
      ofType(AuthActions.checkLogin),
      concatMap(()=>this._authService.checkLogin()),
      map((auth: {user: any, token: any}) => {
        return AuthActions.loginSuccess(auth);
      }),
      catchError(err => {
        console.log("Error obtaining security",err);
        return of(AuthActions.loginFailure(err));
      })
    )
  });

  logout$ = createEffect(()=>{
    return this._actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(()=>this._authService.logout()),
      map((result: [any, boolean]) => {
        return AuthActions.logoutConfirmed();
      }),
      catchError(err =>{
        console.log("Logout Error", err);
        return throwError(err);
      })
    )
  });




}

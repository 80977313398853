export enum AuthRoles{
    BelbinApiAccess = "belbinapi:access",
    ReportAccess = "report:fullAccess",
    FullServiceFullAccess = "fullService:fullAccess",
    FullServiceLimitedAccess = "fullService:limitedAccess",
    FullServiceAllowDetailCostCenter = "fullService:AllowDetailCostCenter",
    FullServiceLimitedAccessNoBilling = "fullService:limitedAccessNoBilling",
    FullServiceEnableSituationalBelbin = "fullService:enableSituationalBelbin",
    FullServiceAllowOrderNumberOverride = "fullService:allowOrderNumberOverride"
    

}
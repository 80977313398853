import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CodeService } from './code.service';
import * as CodeActions from './code.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class CodeEffects {

    constructor(private _actions$: Actions, private _codeService: CodeService) {}

    codeRequested$ = createEffect(()=>{
        return this._actions$.pipe(
          ofType(CodeActions.codeRequested),
          mergeMap((action)=>this._codeService.getCodeItems(action.criteria.codeTableType, action.criteria.parentCodeTableType)),
          map((result) => {
            return CodeActions.codeLoaded({codes: result});
          }),
          catchError(err =>{
            console.log("Logout Error", err);
            return throwError(err);
          })
        )
      });

      codePublicRequested$ = createEffect(()=>{
        return this._actions$.pipe(
          ofType(CodeActions.codePublicRequested),
          mergeMap((action)=>this._codeService.getCodeItemsPublic(action.criteria.codeTableType, action.criteria.parentCodeTableType)),
          map((result) => {
            return CodeActions.codeLoaded({codes: result});
          }),
          catchError(err =>{
            console.log("Logout Error", err);
            return throwError(err);
          })
        )
      });
}

import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { environment } from 'src/environments/environment';
import { Observable, from, bindNodeCallback, combineLatest, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AuthAccess } from './models/auth-access.model';
import { RedirectLoginResult, IdToken } from '@auth0/auth0-spa-js/dist/typings/global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private _client$: Observable<Auth0Client>;
  private _user$: Observable<any>;
  private _isAuthenticated$: Observable<boolean>;
  private _token$: Observable<any>;
  private _redirectCallback$: Observable<RedirectLoginResult>;
  private _logout$: Observable<any>;
  private _claims$: Observable<IdToken>;
  private _auth$: Observable<{user: any, token:any}>;


  constructor() { 
    this._client$ = this.createAuthClient();
    this._user$=this._client$.pipe(concatMap((client: Auth0Client) => from(client.getUser())));
    this._redirectCallback$= this._client$.pipe(concatMap((client: Auth0Client) => from(client.handleRedirectCallback())));
    this._isAuthenticated$ = this._client$.pipe(concatMap((client: Auth0Client) => from(client.isAuthenticated())));
    this._token$ = this._client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently())));
    this._auth$ = combineLatest(this._user$, this._token$, (user: any, token: any) => {return {user: user, token: token}});
  }


  private createAuthClient() : Observable<Auth0Client>
  {
    return from(createAuth0Client({
      client_id: environment.auth.clientID,
      domain: environment.auth.domain,
      responseType: 'token, id_token',
      audience: environment.auth.audience,
      redirect_uri: environment.auth.redirect,
      scope: environment.auth.scope,
      
    }));
  }

  public login(redirectUri: string) :Observable<void> {
    return this._client$.pipe(
      concatMap((client: Auth0Client) => from(client.loginWithRedirect({redirect_uri: redirectUri})))
    );
  }

  public logout() : Observable<any> {
    return this._client$.pipe(
      concatMap((client: Auth0Client) => { 
        client.logout({client_id: environment.auth.clientID, returnTo: environment.auth.logoutUrl, localOnly: false,federated: true });
        return of();
      })
    );
  }

  public checkLogin() : Observable<any>{
    return this._auth$;
  }

  public callback(): Observable<any>{
    return this._redirectCallback$.pipe(
      concatMap(()=>{
        return this._auth$;
      })
    );
  }


}


